import {
  deleteAUserLicence,
  getAllUserLicences,
  getLicences,
  getLicencesByUserId,
  postBulkLicences,
  postUserLicence,
} from '@api/lincences'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ILicences } from '@/types/lincences'
import { message } from 'antd'

type IUserLicence = {
  Licence: ILicences
}

export default function useLicences(searchText?: string) {
  if (searchText) {
    return useQuery<ILicences[]>(['licences', searchText], () =>
      getLicences(`/search/${searchText}`)
    )
  }
  return useQuery<ILicences[]>(['licences', searchText], () => getLicences())
}

export function useLicencesByUserId(userId: string) {
  return useQuery(
    ['licenseByUserId', userId],
    () => getLicencesByUserId(userId),
    {
      enabled: !!userId,
      select: (data: IUserLicence[]) => data.map((item) => item.Licence),
    }
  )
}

export function useLicenceDelete() {
  const queryClient = useQueryClient()
  return useMutation(deleteAUserLicence, {
    onSuccess: (res, variables) => {
      res?.message && message.success(res?.message)
      return queryClient.invalidateQueries([
        'licenseByUserId',
        variables.userId,
      ])
    },
  })
}

export function useLicenceAdd() {
  const queryClient = useQueryClient()
  return useMutation(postUserLicence, {
    onSuccess: (res, variables) => {
      message.success(res?.message || 'User licence successfully added')
      return queryClient.invalidateQueries([
        'licenseByUserId',
        variables?.userId,
      ])
    },
  })
}

export function usePostBulkLicence() {
  return useMutation(postBulkLicences, {
    onSuccess: (res) => {
      message.success(res?.message || 'Updated Successfully')
    },
  })
}

export function useAllUserLicences() {
  return useMutation(getAllUserLicences)
}
