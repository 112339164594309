import { Col, Row, Spin, Switch } from 'antd'
import { useParams } from 'react-router-dom'
import React, { useState } from 'react'
import { useGetAUser, useSetUserStatus } from '@hooks/query/useUser'
import { IBreadcrumbC } from '@/types/breadcrumb'
import BreadcrumbC from '@components/breadcrumb'
import UserForm from '@components/forms/user'
import { UserOutlined, UserSwitchOutlined } from '@ant-design/icons'
import { useLicencesByUserId } from '@hooks/query/useLicences'
import LicencesContainer from '@containers/userManagement/licences'
import { useDeleteUserSelector, useUserSectors } from '@hooks/query/useSectors'
import UserSectorsContainer from '@containers/userManagement/sectors'

const UserContainer = () => {
  const onFinish = (values: unknown) => {
    console.log('Success:', values)
  }

  const { userId = '' } = useParams<{ userId: string }>()
  const { data: userLicences } = useLicencesByUserId(userId)

  if (userId) {
    // const { data, isLoading } = useUser(userId)
    const { data, isLoading } = useGetAUser(userId)
    const { data: userSector } = useUserSectors(userId)

    if (isLoading) {
      return <Spin />
    }

    if (data) {
      const breadcrumbData: IBreadcrumbC[] = [
        {
          key: 'users',
          title: 'Users',
          link: `/users`,
          icon: <UserSwitchOutlined />,
        },
        {
          key: 'user',
          title: `${data.first_name}`,
          icon: <UserOutlined />,
        },
      ]

      return (
        <div
          style={{
            width: '1000px',
          }}
        >
          <BreadcrumbC data={breadcrumbData} />

          <div style={{ paddingTop: 20 }}>
            <UserForm initialValues={data} onFinish={onFinish} />

            <UserSectorsContainer userId={userId} />

            <Row gutter={10}>
              <Col span={4}>
                <p style={{ textAlign: 'right' }}>Agreements:</p>
              </Col>
              <Col span={20}>
                <LicencesContainer
                  isMultiUserMode={false}
                  userLicences={userLicences}
                  userId={userId}
                />
              </Col>
            </Row>
          </div>
        </div>
      )
    }
    return null
  }
  return null
}

export default UserContainer
