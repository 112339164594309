import React, { useState } from 'react'
import { IUser } from '@/types/users'
import { useAllUserLicences } from '@hooks/query/useLicences'
import { Key, TableRowSelection } from 'antd/es/table/interface'
import { useGetUser } from '@hooks/query/useUser'
import { Button, Select, Spin } from 'antd'
import Papa from 'papaparse'
import { ColumnsType } from 'antd/es/table'
import APMTable from '@components/table/apm'

const APMLicencesContainer = () => {
  const FIELDS_TO_KEEP = [
    'user_name',
    'first_name',
    'last_name',
    'email',
    'date_created',
    'marketing_opt_out',
  ]
  interface UsersObject {
    items: IUser[]
    size: number
    total: number
    page: number
  }
  const pageSize = 100
  const [licenceCode, setLicenceCode] = useState<string>('apm')
  const { mutateAsync: userLicenses } = useAllUserLicences()
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])
  const rowSelection: TableRowSelection<IUser> | undefined = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
  }
  const { mutateAsync: userProfile } = useGetUser()
  const [loadedAllProfiles, setLoadedAllProfiles] = useState(false)
  const [loadingProfiles, setLoadingProfiles] = useState(false)
  const [usersObject, setUsersObject] = useState<UsersObject>({
    items: [],
    size: 0,
    total: 0,
    page: 1,
  })
  const [progressText, setProgressText] = useState('')

  const columns: ColumnsType<[IUser]> = [
    {
      title: 'First Name',
      dataIndex: 'first_name',
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
  ]

  const handleLicenceChange = (value: string) => {
    setLicenceCode(value)
    setLoadedAllProfiles(false)
    setUsersObject({
      items: [],
      size: 0,
      total: 0,
      page: 1,
    })
  }
  const generateAPMUsers = async () => {
    setLoadingProfiles(true)
    setProgressText('Starting user generation...')
    const userLicences = []

    try {
      // Fetch the first page to get total count
      const initialResponse = await userLicenses({
        pageSize,
        pageNumber: 1,
        licenceCode,
      })
      userLicences.push(...initialResponse.items)
      const totalPages = Math.ceil(initialResponse.total / pageSize)

      // Fetch remaining pages one by one
      for (let page = 2; page <= totalPages; page++) {
        const response = await userLicenses({
          pageSize,
          pageNumber: page,
          licenceCode,
        })
        userLicences.push(...response.items)
      }

      // Now fetch profiles one by one
      const profiles = []
      for (let i = 0; i < userLicences.length; i++) {
        setProgressText(`Loading profile ${i + 1} of ${userLicences.length}`)
        const profile = await userProfile(userLicences[i].User.uuid)
        profiles.push(profile)
      }

      // Update state once all profiles are fetched
      setUsersObject({
        items: profiles,
        size: profiles.length,
        total: profiles.length,
        page: 1,
      })
      setLoadedAllProfiles(true)
      setProgressText('Completed loading all profiles.')
    } catch (error) {
      console.error('Error loading profiles:', error)
      setProgressText('Failed to load profiles.')
      // Handle errors more gracefully by updating the UI or retrying logic if necessary
    }
    setLoadingProfiles(false)
  }

  function epochToReadableDate(epoch: number): string {
    const date = new Date(epoch * 1000)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')

    return `${year}-${month}-${day}`
  }

  const downloadCSV = () => {
    const filteredUsers = usersObject.items.map((user: any) => {
      return FIELDS_TO_KEEP.reduce((obj: any, key: string) => {
        if (key == 'date_created') {
          obj[key] = epochToReadableDate(user[key])
        } else {
          obj[key] = user[key]
        }
        return obj
      }, {})
    })

    const csv = Papa.unparse(filteredUsers)
    const blob = new Blob([csv], { type: 'text/csv' })
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = `users_${licenceCode}.csv`
    link.click()
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: 40,
          marginTop: 10,
        }}
      >
        <Select
          defaultValue={'apm'}
          style={{
            width: 300,
            height: 30,
          }}
          onChange={handleLicenceChange}
        >
          <Select.Option value="acara">ACARA</Select.Option>
          <Select.Option value="aedc">AEDC</Select.Option>
          <Select.Option value="au_gov_doh">AU Gov DoH</Select.Option>
          <Select.Option value="acecqa">ACECQA</Select.Option>
          <Select.Option value="mwc">Melbourne Water</Select.Option>
          <Select.Option value="unsw">UNSW CFRC</Select.Option>
          <Select.Option value="nhsd_adm">NHSD</Select.Option>
          <Select.Option value="nhsd_aca">NHSD</Select.Option>
          <Select.Option value="geoscape">Geoscape</Select.Option>
          <Select.Option value="apm">APM</Select.Option>
          <Select.Option value="apm_vic">APM Victoria</Select.Option>
          <Select.Option value="apm_nsw">APM New South Wales</Select.Option>
          <Select.Option value="apm_qld">APM Queensland</Select.Option>
          <Select.Option value="apm_sa">APM South Australia</Select.Option>
          <Select.Option value="apm_nt">APM Northern Territory</Select.Option>
          <Select.Option value="apm_wa">APM Western Australia</Select.Option>
          <Select.Option value="apm_tas">APM Tasmania</Select.Option>
          <Select.Option value="apm_act">APM ACT</Select.Option>
          <Select.Option value="lgate_admin">Landgate Admin</Select.Option>
          <Select.Option value="lgate_cadastre">
            Landgate Cadastre
          </Select.Option>
          <Select.Option value="lgate_reserves">
            Landgate Reserves
          </Select.Option>
          <Select.Option value="lgate_poi">
            Landgate Points of Interest
          </Select.Option>
          <Select.Option value="lgate_address">Landgate Address</Select.Option>
          <Select.Option value="lgate_road">Landgate Road</Select.Option>
          <Select.Option value="lgate_tenure">Landgate Tenure</Select.Option>
          <Select.Option value="lgate_topo">Landgate Topology</Select.Option>
        </Select>
        <Button onClick={generateAPMUsers} disabled={loadingProfiles}>
          {!loadingProfiles ? <>Generate</> : <>Generating...</>}
        </Button>
        <Button onClick={downloadCSV} disabled={!loadedAllProfiles}>
          Download
        </Button>
      </div>
      <div className={'row'}>
        {loadingProfiles && (
          <>
            <Spin />
            {progressText}
          </>
        )}
        {loadedAllProfiles && (
          <div>
            <APMTable
              columns={columns}
              rowClassName={(record: IUser, index: number) =>
                index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
              }
              rowKey={'uuid'}
              dataSource={usersObject?.items}
              counts={{
                items: usersObject?.size,
                total: usersObject?.total,
              }}
              style={{ minWidth: '1000px' }}
              size={'middle'}
              rowSelection={rowSelection}
              loading={loadingProfiles}
              pagination={{
                pageSize: usersObject?.size,
                total: usersObject?.total,
                current: usersObject?.page,
                showSizeChanger: false,
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default APMLicencesContainer
