import React, { useState } from 'react'
import { getUsers } from '@api/users.api'
import { Button, Spin } from 'antd'
import { IUser } from '@/types/users'
import Papa from 'papaparse'

const pageSize = 5
const FIELDS_TO_KEEP = [
  'user_name',
  'first_name',
  'last_name',
  'email',
  'date_created',
  'marketing_opt_out',
]

const usersSimpleQuery = async (page: number) => {
  try {
    const response = await getUsers(`?page=${page}&size=${pageSize}`)
    return { items: response.items || [], gotError: false }
  } catch (error) {
    console.warn(`Error fetching page ${page}. Skipping to next page.`)
    return { items: [], gotError: true }
  }
}

function epochToReadableDate(epoch: number): string {
  const date = new Date(epoch * 1000)
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}

const ReportsContainer: React.FC = () => {
  const [allUsers, setAllUsers] = useState<IUser[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const fetchAllUsers = async () => {
    setIsLoading(true)
    const fetchedUsers: IUser[] = []
    let currentPage = 1
    let { items: currentBatch, gotError } = await usersSimpleQuery(currentPage)

    while (currentBatch.length > 0 || gotError) {
      fetchedUsers.push(...currentBatch)
      currentPage++
      const response = await usersSimpleQuery(currentPage)
      currentBatch = response.items
      gotError = response.gotError
    }

    setAllUsers(fetchedUsers)
    setIsLoading(false)
  }

  const downloadCSV = () => {
    const filteredUsers = allUsers.map((user: any) => {
      return FIELDS_TO_KEEP.reduce((obj: any, key: string) => {
        if (key == 'date_created') {
          obj[key] = epochToReadableDate(user[key])
        } else {
          obj[key] = user[key]
        }
        return obj
      }, {})
    })
    const csv = Papa.unparse(filteredUsers)
    const blob = new Blob([csv], { type: 'text/csv' })
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = 'users_report.csv'
    link.click()
  }

  if (isLoading) {
    return <Spin />
  }

  if (allUsers.length > 0) {
    return (
      <div>
        <p>Total Users Fetched: {allUsers.length}</p>
        <Button onClick={downloadCSV}>Download CSV</Button>
      </div>
    )
  } else {
    return (
      <div>
        <p>
          This button generates a CSV report that lists users and whether
          they've opted out of marketing emails.{' '}
        </p>
        <Button onClick={fetchAllUsers}>Start Report Generation</Button>
      </div>
    )
  }
}

export default ReportsContainer
