import { Input, Table, TableProps } from 'antd'
import React, { useState } from 'react'

const TableC = (
  props: TableProps<any> & {
    handleSearch?: (value: string) => void
    handleUserIsActive?: (status: boolean) => void
    searchRowAccessoryLeft?: React.ReactNode
    counts?: {
      items?: number
      total?: number
    }
  }
) => {
  const [searchText, setSearchText] = useState<string>('')

  const searchComponent = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: 40,
          marginTop: 10,
        }}
      >
        {props.searchRowAccessoryLeft && props.searchRowAccessoryLeft}
        <Input
          onPressEnter={() => {
            if (props.handleSearch) {
              props.handleSearch(searchText)
            }
          }}
          data-testid={'searchUser'}
          onChange={(e) => setSearchText(e.target.value.toString())}
          value={searchText}
          style={{
            width: 300,
            height: 30,
          }}
          placeholder={'Search'}
          type={'search'}
        />
      </div>
    )
  }

  const Footer = () => {
    if (props.counts?.items) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flex: 1,
          }}
        >
          <span style={{ fontWeight: 'bold' }}>
            Displaying {props.counts?.items}/{props.counts?.total}
          </span>
        </div>
      )
    }
    return null
  }

  const render = () => {
    return <Table {...props} footer={Footer} />
  }

  return (
    <div>
      {props.handleSearch && searchComponent()}
      {render()}
    </div>
  )
}

export default TableC
