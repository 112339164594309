import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { ColumnsType } from 'antd/es/table'
import { EditOutlined, UserSwitchOutlined } from '@ant-design/icons'
import { Avatar, Tag } from 'antd'
import BulkUpdates from '@containers/userManagement/bulkUpdates'
import { IUser } from '@/types/users'
import useUsers from '@hooks/query/useUsers'
import BreadcrumbC from '@components/breadcrumb'
import { IBreadcrumbC } from '@/types/breadcrumb'
import { Key, TableRowSelection } from 'antd/es/table/interface'
import TableC from '@components/table'

const UsersContainer = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [searchText, setSearchText] = useState<string>('')

  const { data: userData, isLoading } = useUsers(
    currentPage,
    pageSize,
    searchText
  )

  const handleSearch = (value: string) => {
    setSearchText(value)
  }

  const rowSelection: TableRowSelection<IUser> | undefined = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
  }

  const columns: ColumnsType<IUser> = [
    {
      render: (_, record) => (
        <Avatar
          style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
          size={25}
        >
          {record.first_name.charAt(0) + record.last_name.charAt(0)}
        </Avatar>
      ),
    },
    {
      title: 'First Name',
      dataIndex: 'first_name',
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
    },

    {
      title: 'Email',
      dataIndex: 'email',
      sorter: (a, b) => a.email.length - b.email.length,
    },
    {
      title: 'Active',
      dataIndex: 'active',
      filters: [
        {
          text: 'True',
          value: true,
        },
        {
          text: 'False',
          value: false,
        },
      ],
      onFilter: (value, record) => record.active === value,
      render: (value) => {
        return (
          <span>
            {value ? (
              <Tag color={'#87d068'}>True</Tag>
            ) : (
              <Tag color={'#f50'}>False</Tag>
            )}
          </span>
        )
      },
    },
    {
      title: 'Organization',
      dataIndex: 'organization',
      sorter: (a, b) => a.organization.length - b.organization.length,
    },
    {
      title: 'Operations',
      render: (_, form) => (
        <Link to={`/user/${form.uuid}`}>
          <EditOutlined />
        </Link>
      ),
    },
  ]

  const breadcrumbData: IBreadcrumbC[] = [
    {
      key: 'users',
      title: 'Users',
      icon: <UserSwitchOutlined />,
    },
  ]

  return (
    <div>
      <BreadcrumbC data={breadcrumbData} />

      <TableC
        columns={columns}
        rowClassName={(record: IUser, index: number) =>
          index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
        }
        rowKey={'uuid'}
        dataSource={userData?.items}
        counts={{
          items: userData?.size,
          total: userData?.total,
        }}
        style={{ minWidth: '1000px' }}
        size={'middle'}
        rowSelection={rowSelection}
        loading={isLoading}
        pagination={{
          pageSize: userData?.size,
          total: userData?.total,
          current: userData?.page,
          onChange: setCurrentPage,
          showSizeChanger: false,
        }}
        handleSearch={handleSearch}
        searchRowAccessoryLeft={
          selectedRowKeys.length >= 1 && (
            <BulkUpdates
              selectedUsers={selectedRowKeys}
              setSelectedUsers={setSelectedRowKeys}
            />
          )
        }
      />
    </div>
  )
}

export default UsersContainer
