import { getUsers } from '@api/users.api'
import { useQuery } from 'react-query'
import { IUser } from '@/types/users'

type IRes = {
  items: IUser[]
  page: number
  size: number
  total: number
}

export default function useUsers(
  page: number,
  pageSize: number,
  search?: string
) {
  if (search && search !== '') {
    return useQuery<IRes>(['users', search, page], () =>
      getUsers(`/search/${search}`)
    )
  }
  return useQuery<IRes>(['users', page], () =>
    getUsers(`?page=${page}&size=${pageSize}`)
  )
}
