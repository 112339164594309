import { Select, Table, TableProps } from 'antd'
import React from 'react'

const APMTable = (
  props: TableProps<any> & {
    handleUserIsActive?: (status: boolean) => void
    searchRowAccessoryLeft?: React.ReactNode
    counts?: {
      items?: number
      total?: number
    }
  }
) => {
  const Footer = () => {
    if (props.counts?.items) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flex: 1,
          }}
        >
          <span style={{ fontWeight: 'bold' }}>
            Displaying {props.counts?.items}/{props.counts?.total}
          </span>
        </div>
      )
    }
    return null
  }

  const render = () => {
    return <Table {...props} footer={Footer} />
  }

  return <div>{render()}</div>
}

export default APMTable
