import { getUserById, getUserDetails, updateUser } from '@api/users.api'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { IUser } from '@/types/users'
import { AxiosResponse } from 'axios'

export default function useGetUserDetails(
  token: AxiosResponse<string> | undefined | null | string
) {
  return useQuery<IUser>(['userDetails', token], getUserDetails, {
    enabled: !!token,
  })
}

export function useGetAUser(userId: string) {
  return useQuery(['user', userId], () => getUserById(userId), {
    enabled: !!userId,
  })
}

export function useSetUserStatus() {
  const queryClient = useQueryClient()
  return useMutation(updateUser, {
    async onSuccess(res, variables) {
      await queryClient.invalidateQueries('users')
      await queryClient.invalidateQueries('user', variables.userId)
    },
  })
}

export function useGetUser() {
  const queryClient = useQueryClient()
  return useMutation(getUserById, {
    async onSuccess(res, variables) {
      await queryClient.invalidateQueries('user', variables.userId)
    },
  })
}
