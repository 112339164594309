import React from 'react'
import { Link } from 'react-router-dom'
import { Menu } from 'antd'
import { UserOutlined, SnippetsOutlined } from '@ant-design/icons'
// import style from './style.module.less'

function AppSidebar() {
  const handleClick = () => null

  const renderAdminMenu = () => {
    return (
      <Menu
        onClick={handleClick}
        style={{
          width: 256,
          height: '100%',
          backgroundColor: 'rgba(229,226,226,0.10)',
        }}
        defaultSelectedKeys={['users']}
        mode="inline"
      >
        <Menu.Item key="users">
          <Link to={'users/'}>
            <UserOutlined /> User Management
          </Link>
        </Menu.Item>
        <Menu.Item key="reports">
          <Link to={'reports/'}>
            <SnippetsOutlined /> Reports
          </Link>
        </Menu.Item>
        <Menu.Item key="apm-user-licences">
          <Link to={'apm-user-licences/'}>
            <SnippetsOutlined /> APM Licence Users
          </Link>
        </Menu.Item>
      </Menu>
    )
  }

  return renderAdminMenu()
}

export default AppSidebar
