import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  deleteUserSector,
  getSectors,
  getUserSectors,
  postUserSector,
} from '@api/sectors'
import { message } from 'antd'

interface ISelector {
  code: string
  name: string
}

interface IUserSelector {
  Sector: ISelector
}

export function useSectors() {
  return useQuery<ISelector[]>(['sectors'], getSectors)
}

export function useUserSectors(uuid: string) {
  return useQuery(['userSectors', uuid], () => getUserSectors(uuid), {
    select: (data: IUserSelector[] | []) => data.map((item) => item?.Sector),
  })
}

export function useDeleteUserSelector() {
  const queryClient = useQueryClient()
  return useMutation(deleteUserSector, {
    onSuccess: (res, variables: { userId: string; code: string }) => {
      message.success(res?.message || 'Sector deleted successfully')
      return queryClient.invalidateQueries(['userSectors', variables.userId])
    },
  })
}

export function usePostUserSelector() {
  const queryClient = useQueryClient()
  return useMutation(postUserSector, {
    onSuccess: (res, variables: { userId: string; code: string }) => {
      message.success(res?.message || 'Sector set successfully')
      return queryClient.invalidateQueries(['userSectors', variables.userId])
    },
  })
}
