import React, { useEffect, useState } from 'react'
import { Button, Input, Row, Spin, Typography } from 'antd'
import { ILicences } from '@/types/lincences'
import TableC from '@components/table'
import { ColumnsType } from 'antd/es/table'
import { Key, TableRowSelection } from 'antd/es/table/interface'
import { IUser } from '@/types/users'
import useLicences, {
  useLicenceDelete,
  useLicenceAdd,
} from '@hooks/query/useLicences'
import { LoadingOutlined } from '@ant-design/icons'
import { v4 as uuidv4 } from 'uuid'

const { Text } = Typography

interface ILicenseProps {
  value?: [string]
  licences?: ILicences[]
  onChange?: (values: Key[]) => void
  userLicences?: ILicences[]
  isMultiUserMode?: boolean
  userId?: string
  handleSubmit?: () => void
  isLoadingSetBulkLicence?: boolean
}

const UserLicense = ({
  onChange,
  userLicences,
  isMultiUserMode,
  userId,
  handleSubmit,
  isLoadingSetBulkLicence,
}: ILicenseProps) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])
  const [searchText, setSearchText] = useState<string>()
  const { data: licenceData } = useLicences(searchText)

  const {
    mutateAsync: deleteALicence,
    isLoading: loadingDelete,
    variables: variablesDeleteLicence,
  } = useLicenceDelete()

  const {
    mutateAsync: addALicence,
    isLoading: loadingAdd,
    variables: variablesAddLicence,
  } = useLicenceAdd()

  useEffect(() => {
    if (onChange) {
      onChange(selectedRowKeys)
    }
  }, [selectedRowKeys])

  const handleAddUserLicence = (licenceCode: string) => {
    const body = {
      userId: userId,
      code: licenceCode,
    }
    return addALicence(body)
  }

  const handleRevokeUserLicence = (licenceId: string) => {
    return deleteALicence({ licenceId, userId })
  }

  const columns: ColumnsType<ILicences> = [
    {
      key: 'id',
      title: 'ID',
      dataIndex: 'code',
    },
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
    },
    {
      key: 'description',
      title: 'Description',
      dataIndex: 'description',
      render: (value) => {
        if (value.startsWith('https://') && value.endsWith('.pdf')) {
          return (
            <a target={'_blank'} href={value}>
              Show Pdf
            </a>
          )
        }
        return <Text>{value && value.slice(0, 50).trim()}</Text>
      },
    },
    {
      width: 100,
      title: 'Operations',
      render: (_, form) => {
        if (!isMultiUserMode) {
          const isUserHasLicence = userLicences?.find(
            (item) => item.code === form.code
          )
          if (isUserHasLicence) {
            return (
              <Button
                data-testid={'revokeAgreement-' + form.code}
                type={'primary'}
                danger
                size={'small'}
                disabled={loadingDelete || loadingAdd}
                onClick={() => handleRevokeUserLicence(form.code)}
              >
                Revoke
                {loadingDelete &&
                  variablesDeleteLicence?.licenceId === form.code && (
                    <Spin
                      indicator={
                        <LoadingOutlined
                          style={{
                            fontSize: 18,
                            marginLeft: 5,
                          }}
                          spin
                        />
                      }
                    />
                  )}
              </Button>
            )
          } else {
            return (
              <Button
                data-testid={'addAgreement-' + form.code}
                type={'primary'}
                size={'small'}
                disabled={loadingDelete || loadingAdd}
                onClick={() => handleAddUserLicence(form.code)}
              >
                Add
                {loadingAdd && variablesAddLicence?.code === form.code && (
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{
                          fontSize: 18,
                          marginLeft: 5,
                        }}
                        spin
                      />
                    }
                  />
                )}
              </Button>
            )
          }
        }
      },
    },
  ]

  const rowSelection: TableRowSelection<IUser> | undefined = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
  }

  return (
    <div>
      <Row style={{ flexDirection: 'row-reverse', marginBottom: 10 }}>
        <Input
          type={'search'}
          placeholder="Search Agreements"
          style={{ width: 200 }}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </Row>
      <TableC
        dataSource={licenceData}
        columns={columns}
        size={'small'}
        rowKey={'code'}
        // rowKey={() => uuidv4()}
        rowSelection={isMultiUserMode ? rowSelection : undefined}
      />
      {isMultiUserMode &&
        selectedRowKeys.length >= 1 &&
        licenceData &&
        licenceData.length >= 1 &&
        handleSubmit && (
          <Button
            type={'primary'}
            onClick={handleSubmit}
            style={{ float: 'right' }}
            disabled={isLoadingSetBulkLicence}
          >
            {isLoadingSetBulkLicence ? 'Submitting...' : 'Submit'}
          </Button>
        )}
    </div>
  )
}

export default UserLicense
