import React, { useContext } from 'react'
import AppRoutes from '@src/router'
import AppSidebar from '@components/appSidebar'
import { Row, Col } from 'antd'
import { removeToken } from '@utils/storage'
import { AuthCtx } from '@contexts/auth.context'
import LoginContainer from '@containers/login'
import Header from '@components/header'
import Footer from '@components/footer'
import packageJson from '../package.json'

function AppLayout() {
  const { token } = useContext(AuthCtx)

  if (!token) {
    return <LoginContainer />
  }

  const onLogout = () => {
    removeToken()
    window.location.href = `${window._env_.REACT_APP_AUTH_HOST}/logout-me` || ''
  }

  return (
    <div>
      <Header onLogout={onLogout} />
      <Row style={{ marginTop: 70 }}>
        <Col>
          <AppSidebar />
        </Col>
        <Col
          style={{
            overflow: 'scroll',
            flex: 1,
            display: 'flex',
            padding: 10,
            minHeight: 'calc(100vh - 40px)',
            justifyContent: 'center',
            paddingBottom: '200px',
          }}
        >
          <AppRoutes />
        </Col>
      </Row>
      <Footer
        commitHash={process.env.REACT_APP_GIT_SHA}
        versionNo={packageJson.version}
      />
    </div>
  )
}

export default AppLayout
