import EP from '@src/api/endpoints'
import client from '@src/api'

//FIXME: BACKEND: Response schema of /licence and /licence/search needs to be the same
export const getLicences = (params) => {
  return client
    .get(EP.LICENCES(params))
    .then((res) => (res.data?.items ? res.data?.items : res.data))
}

export const postBulkLicences = (body) => {
  return client.post(EP.BULK_LICENCE(), body).then((res) => res.data)
}

export const getLicencesByUserId = (userId) => {
  return client.get(EP.LICENCES_BY_USER_ID(userId)).then((res) => res.data)
}

export const postUserLicence = ({ userId, code }) => {
  return client
    .post(EP.USER_LICENCES_USER_ID(userId), { code })
    .then((res) => res.data)
}

export const deleteAUserLicence = ({ licenceId, userId }) => {
  return client
    .delete(EP.LICENCE_ID_USER_ID(licenceId, userId))
    .then((res) => res.data)
}

export const getAllUserLicences = (params) => {
  return client
    .get(
      EP.USER_LICENCES_PAGINATED(
        params.pageSize,
        params.pageNumber,
        params.licenceCode
      )
    )
    .then((res) => res.data)
}
