import { Breadcrumb } from 'antd'
import { IBreadcrumbC } from '@/types/breadcrumb'
import { Link } from 'react-router-dom'
import { AURIN_COLORS } from '@constants/strings'

const BreadcrumbC = ({ data }: { data: IBreadcrumbC[] }) => {
  return (
    <Breadcrumb
      style={{
        backgroundColor: AURIN_COLORS.green,
        padding: 7,
        width: '100%',
        margin: '5px 0',
      }}
    >
      {data.map((item) => (
        <Breadcrumb.Item key={item.key}>
          <Link to={item.link || ''}>
            <span style={{ color: 'white' }}>
              {item.icon && item.icon} {item.title}
            </span>
          </Link>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  )
}

export default BreadcrumbC
