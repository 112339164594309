import EP from '@src/api/endpoints'
import client from '@src/api'

export const getSectors = () => {
  return client.get(EP.SECTORS).then((res) => res.data?.items || res.data)
}

export const getUserSectors = (uuid) => {
  return client.get(EP.USER_SECTORS(uuid)).then((res) => res.data)
}

export const deleteUserSector = ({ code, userId }) => {
  return client.delete(EP.SECTOR_ONE(userId, code)).then((res) => res.data)
}

export const postUserSector = ({ userId, code }) => {
  return client
    .post(EP.USER_SECTOR, { code, uuid: userId })
    .then((res) => res.data)
}
