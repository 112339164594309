import { Checkbox, Col, Divider, Row, Spin, Tag } from 'antd'
import {
  useDeleteUserSelector,
  usePostUserSelector,
  useUserSectors,
} from '@hooks/query/useSectors'
import React, { useEffect, useState } from 'react'
import { SyncOutlined } from '@ant-design/icons'

const UserSectorsContainer = ({ userId }: { userId: string }) => {
  const { data: userSector } = useUserSectors(userId)
  const [isChecked, setIsChecked] = useState(false)

  useEffect(() => {
    if (userSector) {
      setIsChecked(!!userSector?.find((item) => item.code === 'def'))
    }
  }, [userSector])

  const {
    mutateAsync: deleteUserSector,
    isLoading: loadingDeleteUserSector,
    variables: deleteSectorVariables,
  } = useDeleteUserSelector()

  const { mutateAsync: postUserSector, isLoading: loadingSaveUserSector } =
    usePostUserSelector()

  const handleUserActive = (value: boolean) => {
    if (!value) {
      return deleteUserSector({ code: 'def', userId: userId })
    } else {
      return postUserSector({ code: 'def', userId: userId })
    }
  }

  if (userSector) {
    return (
      <div>
        <Row gutter={10} style={{ marginTop: '-20px' }}>
          <Col span={4}>
            <p style={{ textAlign: 'right' }}>Is User Active?:</p>
          </Col>
          <Col>
            <Checkbox
              data-testid={'markStatus'}
              checked={isChecked}
              onChange={(e) => handleUserActive(e.target.checked)}
              disabled={loadingDeleteUserSector || loadingSaveUserSector}
            >
              {isChecked ? (
                <Tag color={'red'}>Mark as inactive</Tag>
              ) : (
                <Tag color={'green'}> Mark as active</Tag>
              )}
            </Checkbox>

            {(loadingDeleteUserSector || loadingSaveUserSector) && (
              <Spin size={'small'} />
            )}
          </Col>
        </Row>

        <Row gutter={10} style={{ margin: '10px 0px' }}>
          <Col span={4}>
            <p style={{ textAlign: 'right' }}>User Sectors:</p>
          </Col>
          <Col span={20}>
            {userSector.map((item) => (
              <Tag
                color={'green'}
                key={item.code}
                closable
                onClose={() =>
                  deleteUserSector({ code: item?.code, userId: userId })
                }
              >
                {item.name}
              </Tag>
            ))}
          </Col>
          <Divider />
        </Row>
      </div>
    )
  }

  return null
}

export default UserSectorsContainer
