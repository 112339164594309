import React from 'react'
import { Button, Image, Row } from 'antd'
import { AURIN_COLORS } from '@constants/strings'

const LogoutBtn = ({ onLogout }: { onLogout: () => void }) => {
  return (
    <div>
      <Button
        style={{ backgroundColor: AURIN_COLORS.orange, color: 'white' }}
        onClick={() => onLogout()}
      >
        Log out
      </Button>
    </div>
  )
}

const Header = ({ onLogout }: { onLogout: () => void }) => {
  return (
    <Row
      style={{
        padding: '0 10px',
        display: 'flex',
        flex: 1,
        position: 'fixed',
        top: 0,
        width: '100%',
        justifyContent: 'space-between',
        backgroundColor: 'white',
        zIndex: 999,
        alignItems: 'center',
        height: 70,
        borderBottom: '1px solid #EEE',
      }}
    >
      <Image
        src={
          'https://aurin.org.au/wp-content/uploads/2018/07/aurin-logo-400.png'
        }
        width={100}
        preview={false}
        alt={'AURIN logo'}
      />
      <LogoutBtn onLogout={onLogout} />
    </Row>
  )
}

export default Header
