const KEY = 'aurin_admin_token'

export const storeToken = (value: string): void => {
  try {
    localStorage.setItem(KEY, value)
  } catch (e) {
    // saving error
  }
}

export const getToken = () => {
  try {
    return localStorage.getItem(KEY) || null
  } catch (e) {
    // error reading value
  }
}

export const removeToken = (): void => {
  try {
    localStorage.removeItem(KEY)
  } catch (e) {
    // remove error
  }
}
