import React, { useState } from 'react'
import { Button, Drawer } from 'antd'
import { Key } from 'antd/es/table/interface'
import LicencesContainer from '@containers/userManagement/licences'
// import { postBulkLicences } from '@api/lincences'
import { AURIN_COLORS } from '@constants/strings'
import { usePostBulkLicence } from '@hooks/query/useLicences'

type IBulkUpdatesProps = {
  selectedUsers: Key[]
  setSelectedUsers: (values: Key[]) => void
}

const BulkUpdates = ({
  selectedUsers,
  setSelectedUsers,
}: IBulkUpdatesProps) => {
  const [visible, setVisible] = useState(false)
  const [selectedLicences, setSelectedLicences] = useState<Key[]>([])

  const { mutateAsync: postBulkLicences, isLoading: isLoadingSetBulkLicence } =
    usePostBulkLicence()

  const showDrawer = () => {
    setVisible(true)
  }
  const onClose = () => {
    setVisible(false)
  }

  const handleSubmit = () => {
    const licences: { uuid: Key; code: Key }[][] = []
    selectedUsers.map((user) => {
      const body = selectedLicences.map((license) => ({
        uuid: user,
        code: license,
        date_created: 0,
      }))
      licences.push(body)
    })
    const flatBody = licences.flat(1)

    postBulkLicences({
      userlicences: flatBody,
    }).then(() => {
      setSelectedLicences([])
      setSelectedUsers([])
      onClose()
    })
  }

  return (
    <div style={{ height: 40 }}>
      {selectedUsers && selectedUsers.length >= 1 && (
        <Button
          type={'primary'}
          onClick={showDrawer}
          style={{ backgroundColor: AURIN_COLORS.orange, border: 0 }}
        >
          Set Licence
        </Button>
      )}

      <Drawer
        title="Set Licence"
        placement="right"
        onClose={onClose}
        visible={visible}
        width={600}
        destroyOnClose
      >
        <LicencesContainer
          handleSubmit={handleSubmit}
          isMultiUserMode
          isLoadingSetBulkLicence={isLoadingSetBulkLicence}
          onChange={setSelectedLicences}
        />
      </Drawer>
    </div>
  )
}

export default BulkUpdates
