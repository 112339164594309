import { Button, Checkbox, Form, Input, FormProps } from 'antd'
import React from 'react'
import { ILicences, IUserLicence } from '@/types/lincences'
import { IUser } from '@/types/users'

const UserForm: React.FC<
  FormProps & {
    initialValues: IUser
    licences?: ILicences[]
    userLicences?: IUserLicence[]
  }
> = ({ initialValues }) => {
  return (
    <Form
      name="aurin_user_form"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 20 }}
      initialValues={initialValues}
      onFinish={(values) => console.log('form values ->', values)}
      autoComplete="off"
    >
      <div>
        <Form.Item label="First Name" name="first_name">
          <Input
            disabled
            bordered={false}
            style={{ color: 'black', cursor: 'default' }}
          />
        </Form.Item>
      </div>

      <div style={{ marginTop: '-20px' }}>
        <Form.Item label="Last Name" name="last_name">
          <Input
            disabled
            bordered={false}
            style={{ color: 'black', cursor: 'default' }}
          />
        </Form.Item>
      </div>

      <div style={{ marginTop: '-20px' }}>
        <Form.Item label="Email" name="email">
          <Input
            disabled
            bordered={false}
            style={{ color: 'black', cursor: 'default' }}
          />
        </Form.Item>
      </div>

      <div style={{ marginTop: '-20px' }}>
        <Form.Item label="Organization" name="organization">
          <Input
            disabled
            bordered={false}
            style={{ color: 'black', cursor: 'default' }}
          />
        </Form.Item>
      </div>
    </Form>
  )
}

export default UserForm
