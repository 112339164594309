import React from 'react'
import './styles/App.less'
import { BrowserRouter } from 'react-router-dom'
import AppLayout from './layout'
import AuthProvider from '@contexts/auth.context'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { message } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router'
import LoginRoutes from '@src/router/loginRoute'

const Child = () => {
  const navigation = useNavigate()
  const location = useLocation()

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        onError: (err) => {
          try {
            if ([401, 403].includes(err.request.status)) {
              return navigation('/login', { replace: true })
            }
            if (err.request.status === 500 || err.request.status === 0) {
              message.error('Error connecting server')
            }
          } catch (e) {
            console.error('Error ', e)
          }
        },
        cacheTime: 0,
        staleTime: 0,
        retry: false,
      },
    },
  })

  if (location.pathname.startsWith('/login')) {
    return <LoginRoutes />
  }

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <AppLayout />
      </AuthProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

function App() {
  return (
    <BrowserRouter>
      <Child />
    </BrowserRouter>
  )
}

export default App
