import { Button, Image, Layout } from 'antd'
import React from 'react'
import Footer from '@components/footer'
import Lottie from 'react-lottie'
import dataAnalyst from '@src/lottie/data-analyst.json'
import packageJson from '../../../package.json'

const Header = Layout.Header

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: dataAnalyst,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

const HomeContainer = () => {
  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <div style={{ height: '100px' }}>
        <Header style={{ backgroundColor: 'white' }}>
          <Image
            src={
              'https://aurin.org.au/wp-content/uploads/2018/07/aurin-logo-400.png'
            }
            onClick={() => (window.location.href = 'https://aurin.org.au')}
            style={{ cursor: 'pointer' }}
            width={250}
            preview={false}
            alt={'AURIN logo'}
          />
        </Header>
      </div>

      <div
        style={{
          flexGrow: 3,
          alignSelf: 'center',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Lottie options={defaultOptions} height={400} width={400} />
        <Button
          type={'primary'}
          onClick={() =>
            (window.location.href =
              `${window._env_.REACT_APP_AUTH_HOST}/uri-token/?redirectUri=${window._env_.REACT_APP_UI_HOST}/token` ||
              '')
          }
        >
          Login
        </Button>
      </div>

      <div style={{ height: 120 }}>
        <Footer
          commitHash={process.env.REACT_APP_GIT_SHA}
          versionNo={packageJson.version}
        />
      </div>
    </div>
  )
}

export default HomeContainer
