import React from 'react'
import { Route, Routes } from 'react-router-dom'
import UsersPage from '@containers/userManagement/users'
import UserPage from '@containers/userManagement/user'
import UserManagementContainer from '@containers/userManagement'
import ReportsContainer from '@containers/reports'
import { Navigate } from 'react-router'
import APMLicencesContainer from '@containers/apmLicences'

function AppRoutes() {
  return (
    <Routes>
      <Route path="/user/:userId" element={<UserPage />} />
      <Route path="/users" element={<UsersPage />} />
      <Route path="/reports" element={<ReportsContainer />} />
      <Route path="/apm-user-licences" element={<APMLicencesContainer />} />
      <Route path="/" element={<UserManagementContainer />} />
      <Route path="/login" element={<Navigate replace to="/users/" />} />
      <Route path={'/token'} element={<Navigate to={'/users'} replace />} />
    </Routes>
  )
}

export default AppRoutes
