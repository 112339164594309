// eslint-disable-next-line no-undef
const API_BASE =
  window._env_.REACT_APP_API_HOST + '/backend/v1' || 'http://127.0.0.1:8000/api'

const ENDPOINTS = {
  LOGIN: () => `${API_BASE}/auth/login`,
  USERS: (params) => `${API_BASE}/users${params ? params : ''}`,
  USERS_ID: (userId) => `${API_BASE}/user/${userId}`,
  USER_DETAIL: () => `${API_BASE}/user`,
  LICENCES: (params) => `${API_BASE}/agreements${params ? params : ''}`,
  USER_LICENCES: `${API_BASE}/user_agreements`,
  USER_LICENCES_USER_ID: (userId) => `${API_BASE}/user_agreements/${userId}`,
  LICENCES_BY_USER_ID: (userId) => `${ENDPOINTS.USER_LICENCES}/${userId}`,
  LICENCE_ID_USER_ID: (licenceId, userId) =>
    `${ENDPOINTS.USER_LICENCES}/${userId}/${licenceId}`,
  BULK_LICENCE: () => `${API_BASE}/user_agreements/bulk_upload`,
  USER_LICENCES_PAGINATED: (pageSize, pageNumber, licenceCode) =>
    `${ENDPOINTS.USER_LICENCES}/licence/${licenceCode}?size=${pageSize}&page=${pageNumber}`,
  SECTORS: `${API_BASE}/sectors`,
  USER_SECTORS: (uuid) => `${API_BASE}/user_sector/${uuid}`,
  USER_SECTOR: `${API_BASE}/user_sector`,
  SECTOR_ONE: (userId, sector) => `${API_BASE}/user_sector/${userId}/${sector}`,
}

export default ENDPOINTS
