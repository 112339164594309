import React from 'react'
import { Route, Routes } from 'react-router-dom'
import HomeContainer from '@containers/home'

function LoginRoute() {
  return (
    <Routes>
      <Route path="/login" element={<HomeContainer />} />
    </Routes>
  )
}

export default LoginRoute
