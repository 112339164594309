import React, { useEffect, useState } from 'react'
import { getToken, storeToken } from '@utils/storage'
import { useLocation, useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import HomeContainer from '@containers/home'

type IAuthContext = {
  token: string | null | undefined
}

const initialValue: IAuthContext = {
  token: null,
}

export const AuthCtx = React.createContext<IAuthContext>(initialValue)

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [token, setToken] = useState<IAuthContext['token']>(getToken())

  const location = useLocation()
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const devToken = params.get('token')

  /**
   * We use a different strategy to fetch user tokens on development machine.
   * auth service is deployed on a remote host which does not let cross domain
   * cookies to be set. As a result it is not possible to use cookie set by the
   * remote server to be used by localhost to fetch auth tokens
   */
  if (
    // process.env.NODE_ENV === 'development' &&
    location.pathname !== '/token' &&
    !token
  ) {
    navigate('/login', { replace: true })
  }

  useEffect(() => {
    if (devToken) {
      storeToken(devToken)
      setToken(devToken)
    }
  }, [devToken])

  if (!token) {
    return <HomeContainer />
  }

  return <AuthCtx.Provider value={{ token }}>{children}</AuthCtx.Provider>
}

export default AuthProvider
